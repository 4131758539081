'use client'

import ErrorLayout from '@hiretalk/components/layouts/error'

type Props = {
  error: Error & { digest?: string }
  reset: () => void
}

const HomeErrorPage = ({ error, reset }: Props) => {
  return (
    <>
      <ErrorLayout handleReset={() => reset()} message={error.message} color='black' className='bg-opacity-25' />
    </>
  )
}

export default HomeErrorPage
